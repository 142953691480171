import { useEffect, useState } from "react";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import TextInput from "../../components/TextInput";
import { COUNTRY_PHONE_CODES } from "../../constants";
import { validatePhoneNumber } from "../../utils";
import imgLogo from "../../assets/images/logo.png";
import imgRequestAccess from "../../assets/images/request-access.png";
import iconBack from "../../assets/icons/back.png";

const RequestAccessPage = () => {
  const PHONE_CODES = Object.values(COUNTRY_PHONE_CODES).map(item => {
    return {
      value: item.code, label: item.code
    };
  });
  const [phoneCode, setPhoneCode] = useState(PHONE_CODES[0]);
  const [city, setCity] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [requestEnabled, setRequestEnabled] = useState(false);

  function checkInputStatus() {
    if (!phoneNumber.length || !city.length) return false;
    if (phoneNumber.length && !validatePhoneNumber(phoneNumber)) return false;
    return true;
  }

  useEffect(() => {
    const inputStatus = checkInputStatus();
    setRequestEnabled(inputStatus);
  }, [city, phoneNumber]);

  function requestAccess(e) {
    e.stopPropagation();

    if (!checkInputStatus()) return;
    window.location.href = `/request-confirmed`;
  }

  return (
    <div className="RequestAccessPage">
      <div className="left-panel">
        <img
          alt="Request Access"
          className="left-panel-image"
          src={imgRequestAccess}
        />
      </div>
      <div className="right-panel">
        <img
          alt="Logo"
          className="header-logo"
          src={imgLogo}
        />
        <div className="right-panel-content">
          <span className="right-panel-title">
            Get early access to our app
          </span>
          <div className="input-fields">
            <div className="input-fields-phone">
              <Dropdown
                options={PHONE_CODES}
                value={phoneCode}
                selectOption={(phoneCode) => setPhoneCode(phoneCode)}
                maxMenuHeight={100}
              />
              <TextInput
                placeholder="Your mobile number"
                type="tel"
                className="request-access-input"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <TextInput
              placeholder="Your city"
              className="request-access-input"
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <span className="right-panel-description">
            You‘ll receive an SMS code to verify your number
          </span>
          <Button onClick={(e) => requestAccess(e)} className={`request-access-btn ${requestEnabled ? "" : "disabled"}`} disabled={!requestEnabled}>
            Continue
          </Button>
          <span className="privacy-policy-text">
            <span className="highlight">Privacy policy</span> applies. We never share your personal details with a third-parties, unless required to book, manage or operate your flight. Your data is <b>256-bit SSL encrypted</b>, that’s the highest standard applied across the travel and payment industry.
          </span>
        </div>
        <Button onClick={(e) => window.location.href="/login"} className="switch-login-btn">
          Switch to Log in
        </Button>
      </div>
      
      <a className="back-btn" href="/register">
        <img
          alt="back"
          className="back-btn-icon"
          src={iconBack}
        />
      </a>
    </div>
  )
}

export default RequestAccessPage;