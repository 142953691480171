import Header from "../../commons/header/header.jsx";
import Main from "../../commons/main/main";
import Footer from "../../commons/footer-section/footer";

const MainPage = () => {
  return (
    <div className="MainPage">
      <Header />
      <Main />
      <Footer />
    </div>
  )
}

export default MainPage;