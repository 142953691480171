import parse from 'html-react-parser';
import Header from "../../commons/header/header.jsx";
import Footer from "../../commons/footer-section/footer";
import { TERMS_CONDITIONS_ITEMS } from "../../constants";

const TermsConditionsPage = () => {
  return (
    <div className="TermsConditionsPage">
      <Header />
      <div className="terms-conditions-content">
        <span className="terms-conditions-title">Terms and conditions</span>
        <span className="terms-conditions-address">
          Terms of Service Fast Track<br/>
          V1.0 - 13th of July 2020<br/>
          Advanced Travel Technologies LTD<br/>
          11 Staple Inn, London, United Kingdom, WC1V 7QH<br/>
          <a href='mailto:terms@fasttrack.flights'>terms@fasttrack.flights</a>
        </span>
        <span className="terms-conditions-description">
          These Terms of Use shall govern the Fast Track software application service (the “Service”), including, but not limited to, the customer flight check-in management, the flight monitoring and status, flight alerts, and any additional services, whether they are free or provided on a paid subscription basis (Premium Services), as provided or made available by Advanced Travel Technologies LTD (ATT). Please read carefully the terms and conditions contained herein. The downloading of the Fast Track application, registration for, or use of the Service shall be deemed to be your agreement to abide by this Terms of Use including any materials available on the Fast Track software application site incorporated by reference herein (<a href='www.fasttrack.flights'>www.fasttrack.flights</a>), including but not limited to Fast Track Privacy Policy and Fast Track End User License Agreement and will constitute your ongoing acceptance of the Fast Track Terms of Use.<br/><br/>
          <h1>Table of contents</h1><br/>
          <ol>
            <li>Introduction</li>
            <li>Fast Track Responsibilities</li>
            <li>User Responsibilities</li>
            <li>Conditions of Use of the Services and Content</li>
            <li>Fees and Payment for Purchased Services</li>
            <li>Cancellations &amp; Termination</li>
            <li>Confidentiality and Privacy</li>
            <li>Legal Information</li>
            <li>Refund</li>
          </ol>
        </span>
        <div className="terms-conditions-items">
          {TERMS_CONDITIONS_ITEMS.map((termsItem, index) => (
            <div className="terms-conditions-item" key={index}>
              <b className="terms-conditions-item-title">{index + 1}. {termsItem.title}</b>
              <span>{parse(termsItem.content)}</span>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default TermsConditionsPage;