import iconTop from "../../assets/icons/top.svg";

function Navigation() {
  return (
    <div className="navigation" onClick={(e) => window.scroll({top: 0, left: 0, behavior: 'smooth' })}>
      <img
        alt="top"
        className="navigation-top"
        src={iconTop}
      />
    </div>
  );
}

export default Navigation;
