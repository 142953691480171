import React, { useEffect, useState, useRef } from "react";
import Button from "../../components/Button";
import imgLogo from "../../assets/images/logo.png";
import iconNavigation from "../../assets/icons/navigation.svg";

function Header() {
  const ref = useRef();
  const [isScroll, setScroll] = useState(false);
  const [isNavigation, openNavigation] = useState(false);

  function onScroll() {
    if (window.scrollY >= 50) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }
  
  function handleClick(e) {
    if (ref.current && !ref.current.contains(e.target)) {
      openNavigation(false);
      document.body.style.overflow = "auto";
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  return (
    <React.Fragment>
      <header className={`header ${isScroll ? "scroll" : ""}`}>
        <a href="/">
          <img
            alt="Logo"
            className="header-logo"
            src={imgLogo}
          />
        </a>
        <img
          alt="navigation"
          className="header-navigation"
          src={iconNavigation}
          onClick={(e) => {
            openNavigation(true);
            if (document.body.offsetWidth <= 768) {
              document.body.style.overflow = "hidden";
            }
          }}
          ref={ref}
        />
      </header>
      <div className={`navigation-menu ${isNavigation ? "visible" : "hidden"}`}>
        <img
          alt="Logo"
          className="nav-logo"
          src={imgLogo}
        />
        <div className="nav-items">
          <Button onClick={() => window.location.href="/login"} className="nav-login">
            Login
          </Button>
          <p className="nav-or">or</p>
          <Button onClick={() => window.location.href="/register"} className="nav-register">
            Request early access
          </Button>
          <a href="#" className="nav-item">About Air Connect</a>
          <a href="#" className="nav-item">Team</a>
          <a href="#" className="nav-item">FAQs</a>
          <a href="#" className="nav-item">Invest</a>
          <a href="#" className="nav-item">Contact</a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Header;
