import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import TextInput from "../../components/TextInput";
import { COUNTRY_PHONE_CODES } from "../../constants";
import { verificationActions } from '../../actions';
import { validateEmail, validatePhoneNumber } from "../../utils";
import imgLogo from "../../assets/images/logo.png";
import iconPilot from "../../assets/icons/pilot.svg";
import iconBusiness from "../../assets/icons/business.svg";
import iconBack from "../../assets/icons/back.png";

const LoginPage = () => {
  const dispatch = useDispatch();
  const PHONE_CODES = Object.values(COUNTRY_PHONE_CODES).map(item => {
    return {
      value: item.code, label: item.code
    };
  });
  const [phoneCode, setPhoneCode] = useState(PHONE_CODES[0]);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loginEnabled, setLoginEnabled] = useState(false);

  function checkInputStatus() {
    if (!email.length && !phoneNumber.length) return false;
    if (phoneNumber.length && !validatePhoneNumber(phoneNumber)) return false;
    if (email.length && !validateEmail(email)) return false;
    return true;
  }

  useEffect(() => {
    const inputStatus = checkInputStatus();
    setLoginEnabled(inputStatus);
  }, [email, phoneNumber]);

  function login(e) {
    e.stopPropagation();

    if (!checkInputStatus()) return;
    window.location.href = `/verification/${email.length ? "email" : "sms"}`;
  }

  return (
    <div className="LoginPage">
      <div className="left-panel">
        <span className="left-panel-title">Login</span>
        <div className="input-fields">
          <span className="mobile-label">Enter your mobile number</span>
          <div className="input-fields-phone">
            <Dropdown
              options={PHONE_CODES}
              value={phoneCode}
              selectOption={(phoneCode) => {
                setPhoneCode(phoneCode);
                dispatch(verificationActions.setPhoneNumber({ phone_number: phoneNumber, phone_code: phoneCode.value }));
              }}
              maxMenuHeight={100}
            />
            <TextInput
              placeholder="Your mobile number"
              type="tel"
              className="login-mobile-input"
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                dispatch(verificationActions.setPhoneNumber({ phone_number: e.target.value, phone_code: phoneCode.value }));
              }}
            />
          </div>
          <span className="or-text">or</span>
          <TextInput
            placeholder="Type email address"
            type="email"
            className="login-email-input"
            onChange={(e) => {
              setEmail(e.target.value);
              dispatch(verificationActions.setEmail({ email: e.target.value }));
            }}
          />
        </div>
        <Button onClick={(e) => login(e)} className={`login-btn ${loginEnabled ? "" : "disabled"}`} disabled={!loginEnabled}>
          Login
        </Button>
      </div>
      <div className="right-panel">
        <img
          alt="Logo"
          className="right-panel-logo"
          src={imgLogo}
        />
        <span className="right-panel-title">Request early access</span>
        <div className="business-owner">
          <span className="owner-title">For travellers:</span>
          <ol className="owner-options">
            <li>Largest network of helicopters</li>
            <li>Join shared flights</li>
            <li>Access to premium helicopters</li>
            <li>Save time - all your details pre-populated</li>
          </ol>
          <Button onClick={() => window.location.href="/register?type=travellers"} className="owner-access-btn">
            Request now
          </Button>
        </div>
        <div className="business-owner">
          <span className="owner-title">For pilots:</span>
          <ol className="owner-options">
            <li>Get hired more often</li>
          </ol>
          <Button onClick={() => window.location.href="/register?type=pilots"} className="owner-access-btn outline">
            <img
              alt="pilot"
              className="owner-access-icon"
              src={iconPilot}
            />
            For pilots
          </Button>
        </div>
        <div className="business-owner">
          <span className="owner-title">For owners, operators and distributors:</span>
          <ol className="owner-options">
            <li>Fly more clients</li>
            <li>Earn money and rent your helicopter</li>
            <li>Distribute, sell or book helicopter flights</li>
          </ol>
          <Button onClick={() => window.location.href="/register?type=business"} className="owner-access-btn outline">
            <img
              alt="business"
              className="owner-access-icon"
              src={iconBusiness}
            />
            For business
          </Button>
        </div>
      </div>
      <a className="back-btn" href="/">
        <img
          alt="back"
          className="back-btn-icon"
          src={iconBack}
        />
      </a>
    </div>
  )
}

export default LoginPage;