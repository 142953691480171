import React from 'react';
import Select from 'react-select';

const Dropdown = ({...props}) => {
  const { className = "", placeholder = "", value, options, maxMenuHeight = 100 } = props;

  return (
    <Select
      {...props}
      className={`dropdown ${className}`}
      classNamePrefix="dropdown-content"
      options={options}
      value={value}
      placeholder={placeholder}
      onChange={option => props.selectOption(option)}
      maxMenuHeight={maxMenuHeight}
    />
  );
}

export default Dropdown;