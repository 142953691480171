import { getYear } from "../../utils";
import Navigation from "../navigation/navigation";
import imgLogo from "../../assets/images/logo.png";
import imgAmericanExpress from "../../assets/images/american-express.png";
import imgMasterCard from "../../assets/images/mastercard.png";
import imgVisa from "../../assets/images/visa.png";
import imgPaypal from "../../assets/images/paypal.png";
import imgApplePay from "../../assets/images/apple-pay.png";
import imgGooglePay from "../../assets/images/google-pay.png";
import imgAliPay from "../../assets/images/alipay.png";
import imgBitcoin from "../../assets/images/bitcoin.png";
import imgTwitter from "../../assets/images/twitter.png";
import imgFacebook from "../../assets/images/facebook.png";
import imgInstagram from "../../assets/images/instagram.png";
import imgLinkedin from "../../assets/images/linkedin.png";
import imgYoutube from "../../assets/images/youtube.png";
import imgPartner from "../../assets/images/partner1.png";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-menus">
        <div className="footer-menu-texts">
          <img
            alt="Logo"
            className="footer-logo"
            src={imgLogo}
          />
          <div className="footer-menu-text">
            <b>For travellers: </b> We make booking helicopters simple. Request early access to our mobile app. Soon available on all appstores.
          </div>
          <div className="footer-menu-text">
            <b>For business: </b> We provide a booking platform that enables you to distribute helicopter flights as a standalone service, via API, or integrated alongside scheduled flights with conventional airlines bookable via global travel distribution platforms.
          </div>
          <div className="footer-menu-text">
            <b>For pilots: </b> Fly more clients and passengers.
          </div>
        </div>
        <div className="footer-menu-items">
          <div className="footer-menu-item-columns">
            <span className="footer-menu-item main">For travellers</span>
            <a href="#" className="footer-menu-item">Book a flight</a>
            <a href="#" className="footer-menu-item">Airport Transfers</a>
            <a href="#" className="footer-menu-item">Door-to-door</a>
            <a href="#" className="footer-menu-item">Private flights</a>
            <a href="#" className="footer-menu-item">Shared flights</a>
            <a href="#" className="footer-menu-item">Scheduled flights</a>
          </div>
          <div className="footer-menu-item-columns">
            <span className="footer-menu-item main">For business</span>
            <a href="#" className="footer-menu-item">Booking API</a>
            <a href="#" className="footer-menu-item">Booking Manager</a>
            <a href="#" className="footer-menu-item">Fee calculator</a>
            <a href="#" className="footer-menu-item">Payment Gateway</a>
            <a href="#" className="footer-menu-item">Boarding passes</a>
          </div>
          <div className="footer-menu-item-columns">
            <span className="footer-menu-item main">About Us</span>
            <a href="#" className="footer-menu-item">Team</a>
            <a href="#" className="footer-menu-item">Investors</a>
            <a href="#" className="footer-menu-item">Impressum</a>
            <a href="#" className="footer-menu-item">Contact</a>
          </div>
        </div>
      </div>
      <div className="footer-payment">
        <span>We accept payment with: </span>
        <div className="footer-payment-cards">
          <img
            alt="payment-gateway"
            className="footer-payment-image"
            src={imgAmericanExpress}
          />
          <img
            alt="payment-gateway"
            className="footer-payment-image"
            src={imgMasterCard}
          />
          <img
            alt="payment-gateway"
            className="footer-payment-image"
            src={imgVisa}
          />
          <img
            alt="payment-gateway"
            className="footer-payment-image"
            src={imgPaypal}
          />
          <img
            alt="payment-gateway"
            className="footer-payment-image"
            src={imgApplePay}
          />
          <img
            alt="payment-gateway"
            className="footer-payment-image"
            src={imgGooglePay}
          />
          <img
            alt="payment-gateway"
            className="footer-payment-image"
            src={imgAliPay}
          />
        </div>
        <div className="footer-payment-crypto">
          <span>and now as well:</span>
          <img
            alt="payment-gateway"
            className="footer-payment-image"
            src={imgBitcoin}
          />
        </div>
      </div>
      <div className="footer-border" />
      <div className="footer-follow">
        <span>Follow us: </span>&nbsp;&nbsp;
        <a href="https://twitter.com" target="_blank" rel="noreferrer" className="footer-social-link">
          <img
            alt="social"
            className="footer-social-image"
            src={imgTwitter}
          />
        </a>
        <a href="https://facebook.com" target="_blank" rel="noreferrer" className="footer-social-link">
        <img
          alt="social"
          className="footer-social-image"
          src={imgFacebook}
        />
        </a>
        <a href="https://instagram.com" target="_blank" rel="noreferrer" className="footer-social-link">
        <img
          alt="social"
          className="footer-social-image"
          src={imgInstagram}
        />
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noreferrer" className="footer-social-link">
        <img
          alt="social"
          className="footer-social-image"
          src={imgLinkedin}
        />
        </a>
        <a href="https://youtube.com" target="_blank" rel="noreferrer" className="footer-social-link">
        <img
          alt="social"
          className="footer-social-image"
          src={imgYoutube}
        />
        </a>
      </div>
      <div className="footer-privacy">
        <span className="footer-privacy-text">
          © {getYear()} by <b>Air Connect.</b> All rights reserved.
        </span>
        <div className="footer-privacy-terms">
          <a href="/privacy-policy">Privacy</a>. <a href="/terms-conditions">Terms</a>.
        </div>
        <div className="footer-partner">
          <img
            alt="partner"
            className="footer-partner-image"
            src={imgPartner}
          />
          <div className="footer-partner-separator" />
          <span className="footer-partner-text">Strategic<br/>Partner</span>
        </div>
        <div className="footer-language">
          <select className="footer-language-text">
            <option value="english">English</option>
            <option value="germany">Germany</option>
            <option value="french">French</option>
            <option value="chinese">Chinese</option>
            <option value="italian">Italian</option>
          </select>
        </div>
      </div>
      <Navigation />
    </footer>
  );
}

export default Footer;
