import { useEffect, useState } from "react";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import TextInput from "../../components/TextInput";
import { COUNTRY_PHONE_CODES, DEFAULT_NUMBER_USERS } from "../../constants";
import { getWeekDay } from "../../utils";
import imgLogo from "../../assets/images/logo.png";
import iconSms from "../../assets/icons/sms.svg";

function About() {
  const PHONE_CODES = Object.values(COUNTRY_PHONE_CODES).map(item => {
    return {
      value: item.code, label: item.code
    };
  });

  const [phoneCode, setPhoneCode] = useState(PHONE_CODES[0]);
  const [nUsers, setNumberUsers] = useState(DEFAULT_NUMBER_USERS);

  useEffect(() => {
    const weekDay = getWeekDay();
    const dayUsers = DEFAULT_NUMBER_USERS + 200 * weekDay + (20 + Math.floor(Math.random() * 80));
    setNumberUsers(dayUsers);
  }, []);

  return (
    <section className="section-about" id="section-about">
      <img
        alt="Logo"
        className="about-logo"
        src={imgLogo}
      />
      <span className="about-title">
        Book a helicopter
      </span>
      <span className="about-sub-title">
        Anytime. Anywhere.
      </span>
      <span className="about-content">
        We make helicopter booking simple. Select your take-off location, add taxi or limosine pick-up ( via <b>Uber</b>).
        <br /><br />
        We accept last-minute bookings up to 1 hour prior take-off.
        <br /><br />
        Request early access to our mobile app.
      </span>
      <div className="about-request-access">
        <div className="about-request-phone">
          <Dropdown
            className="about-request-dropdown"
            options={PHONE_CODES}
            value={phoneCode}
            selectOption={(phoneCode) => setPhoneCode(phoneCode)}
            maxMenuHeight={100}
          />
          <TextInput
            placeholder="Your mobile number"
            type="tel"
            className="about-request-input"
          />
        </div>
        <TextInput
          placeholder="Your city"
          className="about-request-input"
        />
        <Button onClick={(e) => window.location.href="/register"} className="about-request-btn">
          Request early access
        </Button>
      </div>
      <span className="about-users">
        <span><b className="requested">{nUsers}</b> users requested before you</span>
        <img
          alt="sms"
          className="about-request-sms"
          src={iconSms}
        />
        <span>Get your invite SMS when we launch in your location</span>
      </span>
    </section>
  );
}

export default About;
