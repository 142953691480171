import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Button from "../../components/Button";
import imgCarousel1 from "../../assets/images/carousel1.png";
import imgCarousel2 from "../../assets/images/carousel2.png";

function Tours() {
  return (
    <section className="section-tours" id="section-tours">
      <Carousel
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        autoPlay={true}
        swipeable={true}
        dynamicHeight={true}
        emulateTouch={true}
      >
        <div>
          <img src={imgCarousel1} alt="carousel1" />
        </div>
        <div>
          <img src={imgCarousel2} alt="carousel2" />
        </div>
      </Carousel>
      <div className="tours-content">
        <span className="tours-title">
          Private Flights
        </span>
        <span className="tours-description">
          Set your take-off location, add your destination, and select the best available helicopter for your flight, and book at the best rate.
        </span>
        <Button onClick={(e) => window.location.href="/register"} className="tours-request-btn">
          Request early access &#10141;
        </Button>
        <span className="tours-login">
          or <a href="/login">login</a> to book now
        </span>
      </div>
    </section>
  );
}

export default Tours;
