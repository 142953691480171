export const DEFAULT_NUMBER_USERS = 571;

export const FAQ_LIST = [
  {
    title: "What is Air Connect and whom is it for?",
    description: "Air Connect is an air travel agency aiming to simplify booking of helicopters worldwide. Our helicopter-booking platform is designed to remove human agents from the booking process. When fully digitalized, booking a helicopter becomes quick and easy. Our service is designed for frequent travellers wanting to reach their destinations in shorter time than commercial flights or conventional transport would usually allow."
  },
  {
    title: "How is the flight price calculated?",
    description: "Factors that affect a fare are flight duration and distance, departure time (eg, early-morning or night-flights), helicopter model, airport traffic, take-off and landing tolls, as well as weather conditions and taxes."
  },
  {
    title: "How does it work?",
    description: "Air Connect customers choose the pick-up and drop-off location, date and time for a trip, to submit a helicopter booking request. Following the booking request, Air Connect then matches a traveler with available helicopters nearby which fit the criteria, eg, luggage, pets, cost, etc."
  },
  {
    title: "How long does it take to get a booking confirmed?",
    description: "Air Connect communicates your booking details to the relevant Pilot and  Operator. The time to confirm a booking depends on the supplier we connect you with, and could be in just a few minutes, and up to a few hours. Once your booking request is confirmed, Ar Connect will ping you with a confirmation including all your flight details in the name and on behalf of the fulfillment Operator. You then can either accept or decline your booking. We are "
  },
  {
    title: "Which locations can I book flights in?",
    description: "Air Connect operates globally across more than 50 countries. Depending on your enrollment status you will have access to a service-level including a variety of pick-up locations and arrival destinations. Often customers book a limosine pick-up from their home or hotel, to get to the helicopter take-off location, or get to their final destination. Our goal is to deliver end-to-end travel connections anywhere in the world."
  }
];

export const COUNTRY_PHONE_CODES = {
  AF: {
    name: 'Afghanistan',
    code: '+93'
  },
  AL: {
    name: 'Albania',
    code: '+355'
  },
  DZ: {
    name: 'Algeria',
    code: '+213'
  },
  AS: {
    name: 'American Samoa',
    code: '+1-684'
  },
  AD: {
    name: 'Andorra',
    code: '+376'
  },
  AO: {
    name: 'Angola',
    code: '+244'
  },
  AI: {
    name: 'Anguilla',
    code: '+1-264'
  },
  AQ: {
    name: 'Antarctica',
    code: '+672'
  },
  AG: {
    name: 'Antigua and Barbuda',
    code: '+1-268'
  },
  AR: {
    name: 'Argentina',
    code: '+54'
  },
  AM: {
    name: 'Armenia',
    code: '+374'
  },
  AW: {
    name: 'Aruba',
    code: '+297'
  },
  AU: {
    name: 'Australia',
    code: '+61'
  },
  AT: {
    name: 'Austria',
    code: '+43'
  },
  AZ: {
    name: 'Azerbaijan',
    code: '+994'
  },
  BS: {
    name: 'Bahamas',
    code: '+1-242'
  },
  BH: {
    name: 'Bahrain',
    code: '+973'
  },
  BD: {
    name: 'Bangladesh',
    code: '+880'
  },
  BB: {
    name: 'Barbados',
    code: '+1-246'
  },
  BY: {
    name: 'Belarus',
    code: '+375'
  },
  BE: {
    name: 'Belgium',
    code: '+32'
  },
  BZ: {
    name: 'Belize',
    code: '+501'
  },
  BJ: {
    name: 'Benin',
    code: '+229'
  },
  BM: {
    name: 'Bermuda',
    code: '+1-441'
  },
  BT: {
    name: 'Bhutan',
    code: '+975'
  },
  BO: {
    name: 'Bolivia',
    code: '+591'
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    code: '+387'
  },
  BW: {
    name: 'Botswana',
    code: '+267'
  },
  BR: {
    name: 'Brazil',
    code: '+55'
  },
  IO: {
    name: 'British Indian Ocean Territory',
    code: '+246'
  },
  VG: {
    name: 'British Virgin Islands',
    code: '+1-284'
  },
  BN: {
    name: 'Brunei',
    code: '+673'
  },
  BG: {
    name: 'Bulgaria',
    code: '+359'
  },
  BF: {
    name: 'Burkina Faso',
    code: '+226'
  },
  BI: {
    name: 'Burundi',
    code: '+257'
  },
  KH: {
    name: 'Cambodia',
    code: '+855'
  },
  CM: {
    name: 'Cameroon',
    code: '+237'
  },
  CA: {
    name: 'Canada',
    code: '+1'
  },
  CV: {
    name: 'Cape Verde',
    code: '+238'
  },
  KY: {
    name: 'Cayman Islands',
    code: '+1-345'
  },
  CF: {
    name: 'Central African Republic',
    code: '+236'
  },
  TD: {
    name: 'Chad',
    code: '+235'
  },
  CL: {
    name: 'Chile',
    code: '+56'
  },
  CN: {
    name: 'China',
    code: '+86'
  },
  CX: {
    name: 'Christmas Island',
    code: '+61'
  },
  CC: {
    name: 'Cocos Islands',
    code: '+61'
  },
  CO: {
    name: 'Colombia',
    code: '+57'
  },
  KM: {
    name: 'Comoros',
    code: '+269'
  },
  CK: {
    name: 'Cook Islands',
    code: '+682'
  },
  CR: {
    name: 'Costa Rica',
    code: '+506'
  },
  HR: {
    name: 'Croatia',
    code: '+385'
  },
  CU: {
    name: 'Cuba',
    code: '+53'
  },
  CW: {
    name: 'Curacao',
    code: '+599'
  },
  CY: {
    name: 'Cyprus',
    code: '+357'
  },
  CZ: {
    name: 'Czech Republic',
    code: '+420'
  },
  CD: {
    name: 'Democratic Republic of the Congo',
    code: '+243'
  },
  DK: {
    name: 'Denmark',
    code: '+45'
  },
  DJ: {
    name: 'Djibouti',
    code: '+253'
  },
  DM: {
    name: 'Dominica',
    code: '+1-767'
  },
  DO: {
    name: 'Dominican Republic',
    code: '+1-809, 1-829, 1-849'
  },
  TL: {
    name: 'East Timor',
    code: '+670'
  },
  EC: {
    name: 'Ecuador',
    code: '+593'
  },
  EG: {
    name: 'Egypt',
    code: '+20'
  },
  SV: {
    name: 'El Salvador',
    code: '+503'
  },
  GQ: {
    name: 'Equatorial Guinea',
    code: '+240'
  },
  ER: {
    name: 'Eritrea',
    code: '+291'
  },
  EE: {
    name: 'Estonia',
    code: '+372'
  },
  ET: {
    name: 'Ethiopia',
    code: '+251'
  },
  FK: {
    name: 'Falkland Islands',
    code: '+500'
  },
  FO: {
    name: 'Faroe Islands',
    code: '+298'
  },
  FJ: {
    name: 'Fiji',
    code: '+679'
  },
  FI: {
    name: 'Finland',
    code: '+358'
  },
  FR: {
    name: 'France',
    code: '+33'
  },
  PF: {
    name: 'French Polynesia',
    code: '+689'
  },
  GA: {
    name: 'Gabon',
    code: '+241'
  },
  GM: {
    name: 'Gambia',
    code: '+220'
  },
  GE: {
    name: 'Georgia',
    code: '+995'
  },
  DE: {
    name: 'Germany',
    code: '+49'
  },
  GH: {
    name: 'Ghana',
    code: '+233'
  },
  GI: {
    name: 'Gibraltar',
    code: '+350'
  },
  GR: {
    name: 'Greece',
    code: '+30'
  },
  GL: {
    name: 'Greenland',
    code: '+299'
  },
  GD: {
    name: 'Grenada',
    code: '+1-473'
  },
  GU: {
    name: 'Guam',
    code: '+1-671'
  },
  GT: {
    name: 'Guatemala',
    code: '+502'
  },
  GG: {
    name: 'Guernsey',
    code: '+44-1481'
  },
  GN: {
    name: 'Guinea',
    code: '+224'
  },
  GW: {
    name: 'Guinea-Bissau',
    code: '+245'
  },
  GY: {
    name: 'Guyana',
    code: '+592'
  },
  HT: {
    name: 'Haiti',
    code: '+509'
  },
  HN: {
    name: 'Honduras',
    code: '+504'
  },
  HK: {
    name: 'Hong Kong',
    code: '+852'
  },
  HU: {
    name: 'Hungary',
    code: '+36'
  },
  IS: {
    name: 'Iceland',
    code: '+354'
  },
  IN: {
    name: 'India',
    code: '+91'
  },
  ID: {
    name: 'Indonesia',
    code: '+62'
  },
  IR: {
    name: 'Iran',
    code: '+98'
  },
  IQ: {
    name: 'Iraq',
    code: '+964'
  },
  IE: {
    name: 'Ireland',
    code: '+353'
  },
  IM: {
    name: 'Isle of Man',
    code: '+44-1624'
  },
  IL: {
    name: 'Israel',
    code: '+972'
  },
  IT: {
    name: 'Italy',
    code: '+39'
  },
  CI: {
    name: 'Ivory Coast',
    code: '+225'
  },
  JM: {
    name: 'Jamaica',
    code: '+1-876'
  },
  JP: {
    name: 'Japan',
    code: '+81'
  },
  JE: {
    name: 'Jersey',
    code: '+44-1534'
  },
  JO: {
    name: 'Jordan',
    code: '+962'
  },
  KZ: {
    name: 'Kazakhstan',
    code: '+7'
  },
  KE: {
    name: 'Kenya',
    code: '+254'
  },
  KI: {
    name: 'Kiribati',
    code: '+686'
  },
  XK: {
    name: 'Kosovo',
    code: '+383'
  },
  KW: {
    name: 'Kuwait',
    code: '+965'
  },
  KG: {
    name: 'Kyrgyzstan',
    code: '+996'
  },
  LA: {
    name: 'Laos',
    code: '+856'
  },
  LV: {
    name: 'Latvia',
    code: '+371'
  },
  LB: {
    name: 'Lebanon',
    code: '+961'
  },
  LS: {
    name: 'Lesotho',
    code: '+266'
  },
  LR: {
    name: 'Liberia',
    code: '+231'
  },
  LY: {
    name: 'Libya',
    code: '+218'
  },
  LI: {
    name: 'Liechtenstein',
    code: '+423'
  },
  LT: {
    name: 'Lithuania',
    code: '+370'
  },
  LU: {
    name: 'Luxembourg',
    code: '+352'
  },
  MO: {
    name: 'Macau',
    code: '+853'
  },
  MK: {
    name: 'Macedonia',
    code: '+389'
  },
  MG: {
    name: 'Madagascar',
    code: '+261'
  },
  MW: {
    name: 'Malawi',
    code: '+265'
  },
  MY: {
    name: 'Malaysia',
    code: '+60'
  },
  MV: {
    name: 'Maldives',
    code: '+960'
  },
  ML: {
    name: 'Mali',
    code: '+223'
  },
  MT: {
    name: 'Malta',
    code: '+356'
  },
  MH: {
    name: 'Marshall Islands',
    code: '+692'
  },
  MR: {
    name: 'Mauritania',
    code: '+222'
  },
  MU: {
    name: 'Mauritius',
    code: '+230'
  },
  YT: {
    name: 'Mayotte',
    code: '+262'
  },
  MX: {
    name: 'Mexico',
    code: '+52'
  },
  FM: {
    name: 'Micronesia',
    code: '+691'
  },
  MD: {
    name: 'Moldova',
    code: '+373'
  },
  MC: {
    name: 'Monaco',
    code: '+377'
  },
  MN: {
    name: 'Mongolia',
    code: '+976'
  },
  ME: {
    name: 'Montenegro',
    code: '+382'
  },
  MS: {
    name: 'Montserrat',
    code: '+1-664'
  },
  MA: {
    name: 'Morocco',
    code: '+212'
  },
  MZ: {
    name: 'Mozambique',
    code: '+258'
  },
  MM: {
    name: 'Myanmar',
    code: '+95'
  },
  NA: {
    name: 'Namibia',
    code: '+264'
  },
  NR: {
    name: 'Nauru',
    code: '+674'
  },
  NP: {
    name: 'Nepal',
    code: '+977'
  },
  NL: {
    name: 'Netherlands',
    code: '+31'
  },
  AN: {
    name: 'Netherlands Antilles',
    code: '+599'
  },
  NC: {
    name: 'New Caledonia',
    code: '+687'
  },
  NZ: {
    name: 'New Zealand',
    code: '+64'
  },
  NI: {
    name: 'Nicaragua',
    code: '+505'
  },
  NE: {
    name: 'Niger',
    code: '+227'
  },
  NG: {
    name: 'Nigeria',
    code: '+234'
  },
  NU: {
    name: 'Niue',
    code: '+683'
  },
  KP: {
    name: 'North Korea',
    code: '+850'
  },
  MP: {
    name: 'Northern Mariana Islands',
    code: '+1-670'
  },
  NO: {
    name: 'Norway',
    code: '+47'
  },
  OM: {
    name: 'Oman',
    code: '+968'
  },
  PK: {
    name: 'Pakistan',
    code: '+92'
  },
  PW: {
    name: 'Palau',
    code: '+680'
  },
  PS: {
    name: 'Palestine',
    code: '+970'
  },
  PA: {
    name: 'Panama',
    code: '+507'
  },
  PG: {
    name: 'Papua New Guinea',
    code: '+675'
  },
  PY: {
    name: 'Paraguay',
    code: '+595'
  },
  PE: {
    name: 'Peru',
    code: '+51'
  },
  PH: {
    name: 'Philippines',
    code: '+63'
  },
  PN: {
    name: 'Pitcairn',
    code: '+64'
  },
  PL: {
    name: 'Poland',
    code: '+48'
  },
  PT: {
    name: 'Portugal',
    code: '+351'
  },
  PR: {
    name: 'Puerto Rico',
    code: '+1-787, 1-939'
  },
  QA: {
    name: 'Qatar',
    code: '+974'
  },
  CG: {
    name: 'Republic of the Congo',
    code: '+242'
  },
  RE: {
    name: 'Reunion',
    code: '+262'
  },
  RO: {
    name: 'Romania',
    code: '+40'
  },
  RU: {
    name: 'Russia',
    code: '+7'
  },
  RW: {
    name: 'Rwanda',
    code: '+250'
  },
  BL: {
    name: 'Saint Barthelemy',
    code: '+590'
  },
  SH: {
    name: 'Saint Helena',
    code: '+290'
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    code: '+1-869'
  },
  LC: {
    name: 'Saint Lucia',
    code: '+1-758'
  },
  MF: {
    name: 'Saint Martin',
    code: '+590'
  },
  PM: {
    name: 'Saint Pierre and Miquelon',
    code: '+508'
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    code: '+1-784'
  },
  WS: {
    name: 'Samoa',
    code: '+685'
  },
  SM: {
    name: 'San Marino',
    code: '+378'
  },
  ST: {
    name: 'Sao Tome and Principe',
    code: '+239'
  },
  SA: {
    name: 'Saudi Arabia',
    code: '+966'
  },
  SN: {
    name: 'Senegal',
    code: '+221'
  },
  RS: {
    name: 'Serbia',
    code: '+381'
  },
  SC: {
    name: 'Seychelles',
    code: '+248'
  },
  SL: {
    name: 'Sierra Leone',
    code: '+232'
  },
  SG: {
    name: 'Singapore',
    code: '+65'
  },
  SX: {
    name: 'Sint Maarten',
    code: '+1-721'
  },
  SK: {
    name: 'Slovakia',
    code: '+421'
  },
  SI: {
    name: 'Slovenia',
    code: '+386'
  },
  SB: {
    name: 'Solomon Islands',
    code: '+677'
  },
  SO: {
    name: 'Somalia',
    code: '+252'
  },
  ZA: {
    name: 'South Africa',
    code: '+27'
  },
  KR: {
    name: 'South Korea',
    code: '+82'
  },
  SS: {
    name: 'South Sudan',
    code: '+211'
  },
  ES: {
    name: 'Spain',
    code: '+34'
  },
  LK: {
    name: 'Sri Lanka',
    code: '+94'
  },
  SD: {
    name: 'Sudan',
    code: '+249'
  },
  SR: {
    name: 'Suriname',
    code: '+597'
  },
  SJ: {
    name: 'Svalbard and Jan Mayen',
    code: '+47'
  },
  SZ: {
    name: 'Swaziland',
    code: '+268'
  },
  SE: {
    name: 'Sweden',
    code: '+46'
  },
  CH: {
    name: 'Switzerland',
    code: '+41'
  },
  SY: {
    name: 'Syria',
    code: '+963'
  },
  TW: {
    name: 'Taiwan',
    code: '+886'
  },
  TJ: {
    name: 'Tajikistan',
    code: '+992'
  },
  TZ: {
    name: 'Tanzania',
    code: '+255'
  },
  TH: {
    name: 'Thailand',
    code: '+66'
  },
  TG: {
    name: 'Togo',
    code: '+228'
  },
  TK: {
    name: 'Tokelau',
    code: '+690'
  },
  TO: {
    name: 'Tonga',
    code: '+676'
  },
  TT: {
    name: 'Trinidad and Tobago',
    code: '+1-868'
  },
  TN: {
    name: 'Tunisia',
    code: '+216'
  },
  TR: {
    name: 'Turkey',
    code: '+90'
  },
  TM: {
    name: 'Turkmenistan',
    code: '+993'
  },
  TC: {
    name: 'Turks and Caicos Islands',
    code: '+1-649'
  },
  TV: {
    name: 'Tuvalu',
    code: '+688'
  },
  VI: {
    name: 'U.S. Virgin Islands',
    code: '+1-340'
  },
  UG: {
    name: 'Uganda',
    code: '+256'
  },
  UA: {
    name: 'Ukraine',
    code: '+380'
  },
  AE: {
    name: 'United Arab Emirates',
    code: '+971'
  },
  GB: {
    name: 'United Kingdom',
    code: '+44'
  },
  US: {
    name: 'United States',
    code: '+1'
  },
  UY: {
    name: 'Uruguay',
    code: '+598'
  },
  UZ: {
    name: 'Uzbekistan',
    code: '+998'
  },
  VU: {
    name: 'Vanuatu',
    code: '+678'
  },
  VA: {
    name: 'Vatican',
    code: '+379'
  },
  VE: {
    name: 'Venezuela',
    code: '+58'
  },
  VN: {
    name: 'Vietnam',
    code: '+84'
  },
  WF: {
    name: 'Wallis and Futuna',
    code: '+681'
  },
  EH: {
    name: 'Western Sahara',
    code: '+212'
  },
  YE: {
    name: 'Yemen',
    code: '+967'
  },
  ZM: {
    name: 'Zambia',
    code: '+260'
  },
  ZW: {
    name: 'Zimbabwe',
    code: '+263'
  }
};

export const PRIVACY_POLICY_ITEMS = [
  {
    title: 'General',
    content: `
      The Fast Track Application allows preferential access at exclusive checkpoints, that facilitates a speedy process through security and prior to all procedures before boarding.<br/><br/>
      The FAST TRACK Privacy Policy explains to you in detail the information we collect and how we use that information. The Fast Track Privacy Policy is used to inform application users regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service. The FAST TRACK Service takes It’s user privacy very seriously and will only use user’s information in accordance with this Privacy Policy. FAST TRACK won't sell or rent any user personally identifiable information to third parties. FAST TRACK will hold and transmit user's personal information in a safe, confidential, and secure environment.<br/>
      While using our service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you for verification purposes in the case that you forget your password.<br/>
      If you have questions or concerns regarding this Privacy Policy, please contact our support service at <a href='mailto:privacy@fasttrack.flights'>privacy@fasttrack.flights</a> before using the service. Acceptance of the Privacy Policy and the Terms of Service implies your consent to our use and disclosure of your personal information using the methods described in this Privacy Policy.<br/>
      If you do not want your personal information to be used and saved as described in this Privacy Policy, please do not register for the FAST TRACK service.<br/>
      In contrast, the service can be acquired without saving the personal data, consequently, the information provided would be saved internally and can not be recovered from our systems.
    `
  },
  {
    title: 'Collected Information',
    content: `
      FAST TRACK collects your personal information as a user in order to provide you with the best service possible, including but not limited to:<br/><br/>
      <ol style='list-style: disc'>
        <li>Register and manage your FAST TRACK account;;</li>
        <li>Provide the service (i.e. fast track for your flight), service support, and customize the service to your requirements, ownership of transactional data;</li>
        <li>Grant the service, service alerts (including flight alerts) or promotional materials via email, mobile and web notifications and</li>
        <li>Provide and bill you for services and subscriptions that have been required and ordered by you.</li>
      </ol>
      <br/>
      To provide the FAST TRACK service, we might request the following information:<br/><br/>
      <ol style='list-style: disc'>
        <li>Personal Data; (e.g. your name, last name, postal address, email address, and mobile or other telephone numbers last name, national ID, passport, password, tax code, drivers license and/or social security information)</li>
        <li>Account details: birth date, nationality, gender, address, email address, mobile number.</li>
        <li>Transactional data; Flight information and itineraries; Information required in the Fast track process; Information that might be required to provide you with and perform the service of the fast track;</li>
        <li>Payment information such as transaction identifiers and summary information that does not include credit card or bank account numbers (we do not collect or store financial account information). (cfr. Section 5 "Payments").</li>
        <li>Web behavior information such as information related to how the users use the Products (e.g. browser type, domains, page views) collected through cookies and other automated technology. (cfr. Section 7 "Cookies").</li>
      </ol>
    `
  },
  {
    title: 'Personal Data Usage',
    content: `
      Privacy is a serious concern for us. FAST TRACK (i) collects, (ii) uses, (iii) maintains, and (iv) may share your Personal Data provided by you or collected by us with its affiliates, parent companies, or other related companies for all purposes necessary to ensure the proper functioning and operation of the user accounts and/or the proper functioning of the Products and Services.<br/>
      These purposes may include (collectively the "Purpose"):<br/><br/>
      <ol style='list-style: disc'>
        <li>Creating and managing your Account to use the Application;</li>
        <li>Providing information and allowing the Users access to the Service;</li>
        <li>Diagnosing technical problems and managing technical support and processing inquiries concerning the Service;</li>
        <li>Contacting the Users by phone, email, text message, or push notifications (if they are enabled) to (i) verify your account (ii) for information and operational purposes such as account management, instructions, alerts, reminders, customer service, system maintenance, and others.</li>
        <li>Commercializing the Application.</li>
      </ol>
      <br/>
      The operation, evaluation, and improvement of the Service (including improving the current Service, analysis of our Application, quality control activities and performing internal business functions such as accounting and auditing);* Protect identify and prevent fraud and other unlawful activities, claims and other liabilities;<br/><br/>
      <ol style='list-style: disc'>
        <li>Complying with and enforcing any applicable legal obligations with respect to our Terms of Service and Privacy Policy.</li>
      </ol>
      <br/>
      Furthermore, you agree that FAST TRACK is free to access, retain, and disclose Personal Data in the following events:<br/><br/>
      <ol style='list-style: disc'>
        <li>In order to comply with any applicable legislation or regulations;</li>
        <li>If a law enforcement authority or other government official requests so;</li>
        <li>To enforce the FAST TRACK Terms of service or Privacy Policy</li>
        <li>The investigation of any suspected or actual fraudulent or illegal activity and</li>
        <li>To protect your or our safety and/or rights.</li>
      </ol>
      <br/>
      By using the Application, you acknowledge and explicitly agree that we may disclose certain information to vendors and service providers who help us provide the Service. We will not share your information without your permission, although the user can restore the account upon request.
    `
  },
  {
    title: 'Information Security Measures',
    content: `
      FAST TRACK is committed to treating your information with high standards. FAST TRACK shall take appropriate administrative, technical, and organizational measures against unauthorized or unlawful processing of any Personal Data or its accidental loss, destruction or damage, access, disclosure, or use.<br/><br/>
      Upon written request, FAST TRACK can provide you with a list of entities that may have access to your Personal Data. If such a case, these entities might have entered into confidentiality agreements prior to having been granted access to your Personal Data. Our Sub-processors include Amazon for web-services & storage, Apple for the apple wallet, storing, app, etc. Google for the android version of the app and Slack related to Payments.<br/>
      When providing sensitive information (such as a credit card number) ATT will encrypt the transmission of such information using secure socket layer technology (SSL). ATT follows generally accepted standards to protect the personal information submitted to us, both during transmission and upon receipt. Please note that no method of transmission over the Internet, or method of electronic storage, is 100% secure, therefore, we cannot guarantee its absolute security.<br/>
      In the event of and following discovery or notification of a breach of the security of the Personal Data, or access by an unauthorized person, FAST TRACK is required by law to notify the user if the breach is likely to affect your privacy.<br/><br/>
      These Services do not address anyone under the age of 18. We do not purposely collect personally identifiable information from children under 18. In the case we discover that a child under 18 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to take the necessary actions.<br/><br/>
      Please, do not share your FAST TRACK identification or password information with anyone. If you share your identification or password information you will be liable for any activity related to your information.<br/>
      If there is any doubt that your identification or password information is compromised, please log in to FAST TRACK and change your password. You should never email any sensitive information such as your password, credit card, or bank account information. FAST TRACK will never request you to send your password to us via email. We also recommend you keep your operating system and browser version up-to-date with the latest security patches and actualizations which will help to ensure the protection of your information.<br/><br/>
      FAST TRACK assumes no liability whatsoever for any loss or degradation of such information stored on its systems or any direct or indirect damages arising out of such loss or degradation. Through the use of the FAST TRACK software application, the Licensee may affect the storage of the licensee's own personal data in the FAST TRACK servers. Nothing contained in this section 4 shall be construed to derogate from FAST TRACK rights to use your data to collect FAST TRACK software application users behavior and for statistical purposes, to improve our service, and forward this information to third parties (i.e. airlines) with your prior consent, so as to help those third parties to improve their product and ancillary services.
    `
  },
  {
    title: 'Payments',
    content: `
      The Fast Track application has two different payment methods. (i)The payment by Credit card is free of charge and the Transaction is safe and certified thanks to the use of advanced technologies that allow our payment gateway to anonymously manage and secure your sensitive data. The user can pay with the main credit cards available on the market(Visa, Mastercard, American Express). Financial information will be collected, such as credit card number, expiration date, and the digit security code. All transactions shall be cleared via a leading provider of payment services, and transmission of your credit card information and transactions are secured with SSL standards. (ii) The payments transferred by the digital platform is the most efficient and secure method for users to perform online payments. If the user selects this payment method, only the login credentials can be required in order to proceed with the payment in a few seconds through their account. All the information will be handled entirely by the digital platform, ensuring the protection of your purchases and your data. For the protection of your security, FAST TRACK does not process or store any information about payment instruments (e.g. credit card numbers), which are processed by the respective payment service providers ( Stripe ) transactional data. The actual payment is made after transferring the user to a protected and encrypted page of the banking service. Only after the transaction has taken place, the bank service provider informs FAST TRACK of the outcome of the payment, without providing any information regarding the credit card used. For this reason, FAST TRACK has no power over any refusal of the credit card used for payment. FAST TRACK shall not be held liable in any way for direct or indirect consequences resulting from the use of the credit card by the user to make payment for the products and/or services purchased.
    `
  },
  {
    title: 'Geolocation',
    content: `
      FAST TRACk will obtain your location through the mobile device or directly from you and will be used to provide you with information or services needed or requested by the user. We do not transfer the identity or personally identifiable information of our users to third parties without prior notification and consent, except for providing the desired service.
    `
  },
  {
    title: '”Cookies” use',
    content: `
      When using the Application, we may collect certain information by automated means, such as cookies (small text files stored in your browser) and similar technologies, including the mobile application identifiers, to improve your experience of the Service, increase security, measure use, and effectiveness, identify and resolve issues and marketing purposes.<br/>
      The information we collect in this manner includes IP address, browser characteristics, device characteristics, dates and times of visiting, operating system version, information on actions taken on our Services (such as usage, activity).<br/>
      You can control the cookies through your browser settings and other tools. Your device may offer you control over the use of cookies or other technologies when you use the Application.<br/>
      For example, you may be able to set your device or browser to disable, clear, reset, or block the use of cookies or similar technologies. Please consider, that without cookies the services may not work properly or may not be able to fully work its features by the user.<br/>
      By continuing to use our Service, you consent to the placement of cookies and other automated means in your browser and device in accordance with this Policy Privacy.
    `
  },
  {
    title: 'Disclosure to Third Parties',
    content: `
      In the event of a full or partial merger with, or acquisition of all or part of FAST TRACK, we may transfer your Personal Data to a Third Party. In such an event, FAST TRACK shall impose this Third Party to use any Personal Data strictly consistent with this Privacy Policy.<br/>
      Other than as set out in this Privacy Policy, we shall not sell or otherwise disclose your Personal Data to third parties without obtaining your prior explicit consent unless this is necessary for the purposes set out in this Privacy Policy or unless we are required to do so by law.<br/>
      We may also share Personal Data with Third-Party service providers that help us to provide, understand, commercialize, and improve our Products. We do not authorize these Third-Party service providers to use or disclose your Personal Data except if strictly necessary, to perform any services under our supervision or to comply with applicable legislation. We seek to provide any such Third Party service provider with only the Personal Data they need to perform their specific function.<br/>
      In any event, such third party service providers shall be obliged to treat your Personal Data in accordance with this privacy policy. However, FAST TRACK cannot be held liable for any damages, whether direct or indirect, that may result from the misuse of your Personal Data by such Third-Party service providers.
    `
  },
  {
    title: 'Third Party websites or applications',
    content: `
      You acknowledge and agree that certain content or service provided by third parties may be made available to you through the services. You agree that such linked content or services may have their own privacy policies for which FAST TRACK cannot be held responsible. FAST TRACK does not in any way review or endorse the privacy practices of such third parties.
    `
  },
  {
    title: 'Updates or changes to our Privacy Policy',
    content: `
      The FAST TRACK Privacy Policy may be modified or adjusted as the service evolves and changes. If the methods we use to store and disclose your information changes, you will have the choice as to whether you want to participate in new practices or not, in either case we will notify each user prior to any changes. The Latest version of the Privacy Policy and amendments will be available in the FAST TRACK software application and website for your convenience.
    `
  },
  {
    title: 'Your rights',
    content: `
      Right of access. If you are concerned or have any questions about your Personal data, you have the right to request access to the personal data which we hold or process about you. We will then provide you with information about the data being processed and on the source of the data.<br/>
      Right of rectification and right of erasure. You have the right to request us free of charge to correct, erase, or block any inaccuracies in your Personal Data if such Personal Data would be incomplete, inaccurate, or processed unlawfully.<br/><br/>
      In case of inquiries, questions, or concerns about our Privacy Policy or our practices, you may email us at support (at) <a href='mailto:privacy@fasttrack.flights'>privacy@fasttrack.flights</a>.
    `
  }
];

export const TERMS_CONDITIONS_ITEMS = [
  {
    title: 'Introduction',
    content: `
      Please read these terms of service carefully before using the Fast Track app operated by The Company.<br/>
      The user’s access to and use of the Fast Track services is conditioned on the user’s acceptance and compliance with these Terms. The following Terms apply to all visitors, users, legal or natural persons who access or use the Service. By accessing or using Fast Track services, the user agrees to be bound by these Terms. If the user disagrees with any part of these Terms, then the user shall not access this service.<br/><br/>
      The Company reserves the right, in their sole discretion, to make changes or modifications to these Terms of service at any time and for any reason. The users shall be alerted about any changes by updating the “Last updated” date of these Terms of Service and the user waives any right to receive specific notice of each such change. It is the user’s responsibility to periodically review these Terms of Service to stay informed of updates. The user shall be subject to, and shall be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Service by the continued use of the website or the app after the date such revised Terms are posted.<br/><br/>
      <b>Modification of the Terms of Use</b><br/>
      Advanced Travel Technologies LTD. Software applications are a work in progress, therefore ATT reserves the right to replace or modify, even dramatically, the Advanced Travel Technologies LTD. Terms of Use at any time and at its sole discretion. The date of the last update of the Advanced Travel Technologies LTD. Terms of Use will be indicated at the top of this document. Any changes will be effective immediately upon posting of the revised version. It is the user’s responsibility to regularly review the Terms of Use to check if new changes have been included. Your continued use of the Service following the posting of any changes to the Terms of Use will constitute your acceptance of any such changes. If you do not agree with the included changes, you must stop using the Service. Your failure to abide by the Advanced Travel Technologies LTD. Terms of Use or any further terms or conditions regulating the Service may result in suspension or termination of your access to the Service, without notice, in addition to any other remedies available to ATT.<br/><br/>
      <b>Account Information and Data</b><br/>
      You may register with Advanced Travel Technologies LTD. to create an account (“Account”). You shall select a password and receive an account upon completing the registration process. The use of Accounts is subject to this Agreement. ATT does not own any data, information, or material that you submit to the Service in the course of using the Service (" Customer Data "). The User and not ATT shall be solely and exclusively responsible for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use all Customer Data, and ATT shall not be responsible or liable for the deletion, correction, destruction, damage, loss or failure to store any Customer Data. ATT reserves the right to withhold, remove and/or discard Customer Data without notice for any breach, including, without limitation, your non-payment. Upon termination for cause, your right to access or use Customer Data immediately ceases, and ATT shall have no obligation to maintain or forward any Customer Data.
    `
  },
  {
    title: 'ATT Responsibilities',
    content: `
      The Service includes access to Advanced Travel Technologies LTD. software application and all its functionalities, accessible through a Smartphone, PDA or computer, or any other platform or device where the software is executable. The Fast Track warrants that it shall perform its obligations under this Agreement and the Privacy Policy at all times: (i) on a professional basis in accordance with the highest standards in its industry, (ii) in accordance with the relevant Applicable Law and regulations.<br/><br/>
      The Company shall not be held responsible by the User for any content published by any third party hereto.
    `
  },
  {
    title: 'User Responsibilities',
    content: `
      It is the user’s responsibility to ensure there is adequate time left to clear airport security prior to the flight. Please contact the airline or check the ticket for advice on how early to arrive at the airport prior to the flight. The Company does not accept any liability if the user misses their flight by not respecting to allocate enough time to clear security.<br/><br/>
      You are solely and exclusively responsible for all activity occurring under your use of the account and shall abide by all software application local, state, national and foreign laws, treaties, and regulations in connection with your use of the software application and its services, including those related to data privacy, international communications and the transmission of technical or personal data. You shall: (i) notify ATT immediately of any unauthorized use of any password or account or any other known or suspected breach of security; (ii) report to ATT immediately and use reasonable efforts to stop immediately any copying or distribution of content that is known or suspected by you; and (iii) not impersonate another ATT user or provide false identity information to gain access to or use the software application.<br/><br/>
      The user’s booking is made on a pre-booked time slot allocation. Therefore, it is the user’s responsibility to ensure timely arrival within the booked time. If for any reason the user misses the allocated time slot, The Company will endeavour to ensure access to Fast Track Security, however, this cannot be guaranteed.<br/><br/>
      The user must bring confirmation of the booking in order to use Fast Track Security.<br/>
      The user must not resell or transfer any booking (in whole or in part).<br/><br/>
      As part of the booking process the user will need to submit the following details:<br/><br/>
      <ol>
        <li>- Select the date and time slot that the user wishes to use the FastTrack security lane,</li>
        <li>- Select the departure terminal, and the number of FastTrack tickets wished to purchase.</li>
        <li>- After the payment has been recorded and the credit card has an authorization hold (see cfr. Section 5 "Fee and Payments"), the user has to introduce the names of each passenger that will use the Fast Track passes. Please note that the names have to be exactly as stated in the passports.</li>
      </ol>
      <br/>
      The user has to take the time to read and check the order during the booking process. After this, it will be requested to submit payment information to confirm the order. Furthermore, Advance Passenger Information (API), can be asked including the type of passenger, citizenship, passport, address, etc.
    `
  },
  {
    title: 'Conditions of Use of the Services and Content',
    content: `
      The services are provided under the following Terms of Service, Fast Track will provide to the user an app that will allow them to book in advance, as well as book for immediate usage and utilize a Fast Track Pass that will allow them to use a special lane dedicated to avoiding the long queues at the security area.<br/><br/>
      The booking is only valid for the specific date and time stated on the Fast-track pass. The Fast-Track pass is not transferable to another person once it has been issued. The Company cannot guarantee the availability of the service. On top of that, the right to use Fast Track Security may be withdrawn at any time without notice by the Airport. The Company reserves the right to limit, extend, alter and/or prohibit access through Fast Track Security for one or more of the following; operational, security, contingency, or emergency reasons. In these circumstances, passengers would possibly be redirected to the main security area but will be issued a full refund.<br/><br/>
      The purchase of Fast Track Security only provides access to the Fast Track Security area. No assurances can be provided regarding how much time it could take to clear security. This product does not in any way excuse the user from the requirement of processing to a full security screening.<br/>
      Bookings can be made by adults only. The cardholder’s permission must be granted if the user is underage.<br/><br/>
      The price paid by the user is the price accepted and confirmed to the purchaser via the Fast Track app. All prices are in the currency the User might have selected by default or by their own selection and include Value Added Tax (VAT) and any other applicable taxes. The booking shall be valid for a single passage through the FastTrack lane for the date booked.<br/><br/>
      As part of the booking process the purchaser shall provide the following details: select the desired date and time slot to use the FastTrack security lane, departure terminal, and the quantity of FastTrack tickets the purchaser wishes to purchase. Please take the time to read and check the order at each page of the booking process. The purchaser shall then be asked to submit their payment method details and to confirm the booking.<br/><br/>
      <b>Prohibited Uses</b><br/><br/>
      You may use the software application only for your personal and internal purpose and shall not: (i) interfere with or disrupt the integrity or performance of the software application or the data contained therein or intercept,monitor, damage or modify any communication which is not intended for you; or (ii) attempt to gain unauthorized access to the software application or its related systems or networks; (iii) send spam or otherwise duplicative or unsolicited messages in violation of software application laws; (iv) send or store infringing, obscene, threatening, libelous, or otherwise unlawful material, including material harmful to children or of any third party privacy rights; (v) send or store material containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents or programs that are designed to distort, delete, damage or disassemble the Advanced Travel Technologies LTD. software application or communication. You may not undertake, cause, permit or authorize to "frame" or "mirror" any content of the Advanced Travel Technologies LTD. software application on any other server or wireless or Internet-based device copy, display, distribute, perform, publish, modify, create (derivative) works, translate, reverse engineering, decompile, disassemble or hack the Advanced Travel Technologies LTD. software application and/or Advanced Travel Technologies LTD. services or any part thereof, in order to (a) build a competitive product or service, (b) build a product using similar ideas, features, functions or graphics of the Service, or (c) copy any ideas, features, functions or graphics of the Service.<br/><br/>
      To use any of our services or to register for an account, the user who pays for the service must be an adult and agree to our terms. When creating an account with the company, the user is responsible for the account, it’s security (eg, not to share credentials, etc.), and all the activity on it.<br/><br/>
      The Company provides different methods through which the user may interact with our services.<br/>
      The user agrees to our Privacy Policy. Any password will be encrypted and is confidential and shall only be known to the user . The security of the password shall require it to consist of at least 1 capital letter, 1 number, and will consist of a length of a minimum of 8 digits.<br/><br/>
      The Company in compliance with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements;<br/>
      The responsibility for all transactions and other activity placed or conducted through the user account releases The Company from any and all liability concerning such transactions or activities;<br/>
      Thereof will contact <a href='mailto:support@fasttrack.flights'>support@fasttrack.flights</a> immediately for any actual or suspected loss, theft, or unauthorized use of the account or password. The service will be used in a good manner.<br/><br/>
      The Company cannot ensure the availability of the service to the user and the right to access Fast Track Security can be withdrawn at any given time without prior warning from the airport. The Company shall not be held responsible when the airport restricts, extends, modifies, and/or prohibits access to the Fast Track Security lane for operational, security, contingency, and/or emergency reasons.<br/><br/>
      <h1>Fast Track Status</h1><br/>
      The Fast Track app will show different Fast Track pass statuses, each one will represent a specific situation of the Fast Track pass process.<br/><br/>
      <ol style='list-style: disc'>
        <li>&#8594; In Progress<br/>The status in progress converges to the Fast Track passes that have been purchased, yet the process of issuing is not open due to operational reasons from the Departure Airport or Airline. Consequently, the Fast Track pass cannot be edited since all the necessary data has been sent previously.</li>
        <li>&#8594; Issued<br/>This is the final status of the Fast Track pass purchase process, wherein the definitive version of the Fast Track pass is received and can be viewed by the user. Once the Fast Track passes are issued, there is an option for non-refundable or cancellations available, for more information please refer to the section Cancellations.</li>
      </ol>
    `
  },
  {
    title: 'Fees and Payments for Purchased Services',
    content: `
      Payments may be made using one of the payment methods set out in the Fast Track app. The user payments can only be made using a valid credit card. We reserve the right to not process your booking (i) if your card is declined for any reason, (ii) if the payment card has been used fraudulently or (iii) without the cardholder's permission or (iv) without the cardholder's tutor’s permission or (v) if you are underage.<br/><br/>
      For the payment method, an authorization hold will be issued to the Credit Card with the total amount of costs surrounding the purchase of Fast Track passes. When the pass is still in the processing stage “in process”, no charges shall be applied to the Credit Card. The total amount of the charges shall be reserved and deducted from the account holder's Credit Card only after the Fast Track pass has been issued.<br/><br/>
      The hold-authorisation feature works in four steps:<br/><br/>
      <ol>
        <li>When the user requests a Fast Track pass by using the Fast Track app, an authorisation hold is placed on the Credit Card used, amounting to the upfront fare. Once the Fast Track pass is issued, the pending payment is converted to a final charge, and only then is the account debited.</li>
        <li>If the issued Credit Card account balance is lower than the necessary amount billed, the authorisation hold will fail. If this occurs, the user will have to select a different payment method or add a new payment method to their account in order to request a Fast Track pass.</li>
        <li>When the user has completed the booking procedure by providing all the necessary details, including the requirements, the status “in progress” of the Fast Track passes will be presented. The user is responsible for supplying valid information as it is not possible to modify or cancel the purchase once the Fast Track pass is issued. Additionally, The Company can not be held responsible for non-delivery due to transmission failure or incorrect details provided by the user. The user agrees that the API and payment card information from the related user may be provided to third parties such as governmental and/or anti-fraud institutions, for payment processing and fraud prevention purposes.</li>
        <li>The applicable fees and taxes in the Fast Track App for the services applied may change at any time and shall come into effect immediately. The user agrees to pay all applicable, undisputed fees and taxes for the services on the terms laid out in the invoice.</li>
      </ol>
    `
  },
  {
    title: 'Cancellations & Termination',
    content: `
      The user may opt to cancel any Fast Track pass in the process status. When the user decides to cancel a Fast Track pass which is already in progress, the authorisation hold of the Credit Card will be immediately released.<br/><br/>
      If the status of your Fast Track pass is “pending” or “in process”, any cancellation can be made. Once the user has written the names and discovered a mistake, the processable Fast Track pass can be cancelled, releasing the authorization hold amount charged in the credit card and may place a new order. Please note, there are no cancellations possible after the Fast Track pass is issued. Moreover, since there is an authorization hold applicable, refunds are not valid. The Service shall also be terminated by the Fast Track App in case of a delinquent Account or in case of a breach of any of the user’s obligations as outlined in the Terms of service established here.<br/><br/>
    `
  },
  {
    title: 'Confidentiality and Privacy',
    content: `
      This section is used to inform users regarding the policies surrounding the collection, use, and disclosure of Personal Information if anyone decides to use the Service. If users choose to make use of our Service, then they agree to the collection and use of information in relation to this policy. The Personal Information that is collected, is used for providing and improving the Service. This Personal Information shall not be used or shared with others different than as described in this Privacy Policy. The terms used in the Privacy Policy have the same meanings as in our Terms of Service, which is accessible by the User, unless otherwise defined in this Privacy Policy.<br/><br/>
      <ol>
        <li>Information Collection and Use<br/>For a better experience, while using the Fast Track App, the user may be required to provide certain personally identifiable information. The information requested shall be retained, and used as described in the privacy policy.</li>
        <li>Security<br/>Users’ trustful providence of Personal Information is valued by The Company, thus, it is strived to use commercially acceptable means of protecting this data. Remember that no method of transmission over the Internet, or method of electronic storage is 100% secure and reliable, therefore, no absolute security can be guaranteed.</li>
        <li>Children’s Privacy<br/>These Services do not address persons underage. The Company does not knowingly collect personally identifiable information from underage. In the case where it is discovered that an underage person has provided us with personal information, this information shall be deleted immediately from the app’s servers. If the user is a parent or guardian/tutor, and is aware that their child has provided Personal Information, please contact The Company in order to undertake the necessary actions. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to take the necessary actions.</li>
        <li>Changes to the Privacy Policy<br/>The Company may update its Privacy Policy periodically. Thus, the user is advised to review this page periodically for any changes. The user shall be notified of any changes made by publishing the new Privacy Policy on this page. These changes are effective immediately after publication on this document.</li>
      </ol>
      Contact Us<br/><br/>
      For any further questions please read our Privacy Policy, please do not hesitate to contact The Company at <a href='mailto:terms@fasttrack.flights'>terms@fasttrack.flights</a>.
    `
  },
  {
    title: 'Jurisdiction',
    content: `
      With express waiver to any other jurisdiction that may correspond to the Parties, any dispute or controversy in relation to, in connection with or resulting from this License shall be exclusively resolved by the courts of the city of London (England). This License will be governed by and construed under the laws of the Kingdom of England.
    `
  },
  {
    title: 'Refund',
    content: `
      The way the refund is handled, depends on the original payment method.<br/><br/>
      Once the request for refund is received, it will be inspected and the user shall be notified if all the requested documentation is correct.<br/><br/>
      The user shall immediately be notified on the status of their refund after inspection of the documentation. After that, a refund shall be sent to the user’s Credit Card (or through the original payment method). Once payment to third parties has been effective, ATT is tied to obligations. As a result, the total refund might be up to a certain percentage. All refunds are processed in the currency used by the user, and will reflect the exchange rate in effect on the date of the refund, therefore, all refunds are subject to this fluctuation. Advanced Travel Technologies LTD. is not responsible for any change in exchange rates between the time of payment and time of refund.<br/><br/>
      The user will receive the refund within a certain amount of days, depending on the Credit Card issuer's policies.<br/><br/>
      Advanced Travel Technologies LTD. reserves the right to provide a refund percentage based on its discretion.<br/><br/>
      Users are eligible for reimbursement if they request a refund within 15 calendar days starting from the date written on the Fast Track Pass. After the 15-day period users shall no longer be eligible and shall not be able to receive a refund.<br/><br/>
      <h1>Late or Missing Refunds</h1><br/>
      If it becomes apparent that a refund has not yet been received, users are advised to check their bank account once again. Then contact the Credit Card company; it may take some time before the refund is officially posted. Next contact the bank; there is often some processing time before a refund is posted. If users have taken all these steps and still have not received the refund, please contact the Company via email (<a href='mailto:refunds@fasttrack.flights'>refunds@fasttrack.flights</a>).<br/><br/>
      THE USER EXPRESSLY ACKNOWLEDGES TO HAVE READ THE TERMS OF USE AND UNDERSTANDS THE RIGHTS, AND OBLIGATIONS SET FORTH HEREIN. BY ACCESSING OR OTHERWISE USING OUR SERVICES (EG, VISITING THE WEBSITE, SEARCHING FOR AVAILABILITY, CONSULT PRICES, OR MAKE A PURCHASE, OR REGISTERING AN ACCOUNT) AND/OR CONTINUING TO INSTALL OR USE THE COMPANY’S SOFTWARE APPLICATION AND SERVICES, THE USER EXPRESSLY CONSENTS TO BE BOUND BY ITS TERMS OF SERVICE AND GRANTS TO ADVANCED TRAVEL TECHNOLOGIES LTD. THE RIGHTS SET FORTH HEREIN.
    `
  }
];
