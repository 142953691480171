const verification = (
  state = {
    email: "",
    phone_code: "",
    phone_number: "",
  },
  action
) => {
  switch (action.type) {
    case "SET_EMAIL":
      return {
        email: action.email,
      };
    case "SET_PHONE_NUMBER":
      return {
        phone_code: action.phone_code,
        phone_number: action.phone_number,
      };
    default:
      return state;
  }
};

export default verification;
