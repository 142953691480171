import { FAQ_LIST } from "../../constants";
import FaqItem from "./FaqItem";

function Stories() {
  return (
    <section className="section-stories" id="section-stories">
      <span className="stories-title">
        Frequently Asked Questions
      </span>
      <span className="stories-sub-title">
        About our Booking Platform
      </span>
      <div className="stories-faq-items">
        {FAQ_LIST.map((faqItem, index) => (
          <FaqItem
            key={index}
            title={faqItem.title}
            description={faqItem.description}
          />
        ))}
      </div>
    </section>
  );
}

export default Stories;
