import iconPhone from "../../assets/icons/phone.svg";

function Contact() {
  return (
    <section className="section-contact" id="section-contact">
      <span className="contact-title">
        Speak to us 24/7
      </span>
      <div className="contact-phone">
        <img
          alt="phone"
          className="phone-icon"
          src={iconPhone}
        />
        <span className="phone-number">
          +44 (0)7745 55378
        </span>
      </div>
      <span className="contact-text">
        We are here to help you
      </span>
    </section>
  );
}

export default Contact;
