export function getWeekDay() {
  const date = new Date();
  const weekDay = date.getDay();
  if (weekDay === 0) return 6; // Sunday => 6
  return weekDay - 1;
};

export function getYear() {
  const date = new Date();
  return date.getFullYear();
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validatePhoneNumber(phoneNumber) {
  const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return re.test(phoneNumber);
}
