import imgLogo from "../../assets/images/logo.png";
import iconWorld from "../../assets/icons/world.svg";
import iconCalendar from "../../assets/icons/calendar.svg";
import iconTime from "../../assets/icons/time.svg";
import iconSecurity from "../../assets/icons/security.svg";

function Feature() {
  return (
    <section className="section-features" id="section-features">
      <img
        alt="Logo"
        className="feature-logo"
        src={imgLogo}
      />
      <div className="feature-items">
        <div className="feature-item">
          <img
            alt="world"
            className="feature-item-icon"
            src={iconWorld}
          />
          <span className="feature-item-title">
            1,000+ locations
          </span>
          <span className="feature-item-content">
            With locations worldwide, you can choose almost any take-off location, and we arrange you a helicopter pickup.
          </span>
        </div>

        <div className="feature-item">
          <img
            alt="calendar"
            className="feature-item-icon"
            src={iconCalendar}
          />
          <span className="feature-item-title">
            Instant booking
          </span>
          <span className="feature-item-content">
            Choose a destination, select a helicopter model, and book instantly.
          </span>
        </div>

        <div className="feature-item">
          <img
            alt="time"
            className="feature-item-icon"
            src={iconTime}
          />
          <span className="feature-item-title">
            Book last-minute
          </span>
          <span className="feature-item-content">
            Pick-ups in less than 1 hour after confirmed booking.
          </span>
        </div>

        <div className="feature-item">
          <img
            alt="security"
            className="feature-item-icon"
            src={iconSecurity}
          />
          <span className="feature-item-title">
            Secure Payment
          </span>
          <span className="feature-item-content">
            Pay via credit card online. No need to share card details over the phone or via email.
          </span>
        </div>
      </div>
    </section>
  );
}

export default Feature;
