import { useEffect, useState } from "react";
import Button from "../../components/Button";
import imgLogo from "../../assets/images/logo.png";
import iconPilot from "../../assets/icons/pilot.svg";
import iconBusiness from "../../assets/icons/business.svg";
import iconBack from "../../assets/icons/back.png";

const RegisterPage =  () => {
  const [type, setType] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("type"))
      setType(params.get("type"));
  }, []);

  function requestAccess(type) {
    setType(type);
    window.location.href="/request-access";
  }

  return (
    <div className="RegisterPage">
      <div className="left-panel">
        <span className="left-panel-title">Get early access to our app</span>
        <div className="account-types">
          <span className="account-types-title">Select type of account</span>
          <Button onClick={(e) => requestAccess("travellers")} className={`account-types-btn ${type === "travellers" ? "" : "outline"}`}>
            For travellers
          </Button>
          <Button onClick={(e) => requestAccess("pilots")} className={`account-types-btn ${type === "pilots" ? "" : "outline"}`}>
            <img
              alt="pilot"
              className="account-types-icon"
              src={iconPilot}
            />
            For pilots
          </Button>
          <Button onClick={(e) => requestAccess("business")} className={`account-types-btn ${type === "business" ? "" : "outline"}`}>
            <img
              alt="business"
              className="account-types-icon"
              src={iconBusiness}
            />
            For business
          </Button>
        </div>
        <div className="business-owner">
          <span className="owner-title">For retailers:</span>
          <span className="owner-description">
            We re-sell flights, license our booking software, and provide access to our API.
            We enter partnerships with online travel agencies, private jet charter companies, global distributor systems (GDS), flight search engines, flight meta booking websites/apps.
          </span>
        </div>
        <div className="business-owner">
          <span className="owner-title">For heli apps / webs:</span>
          <span className="owner-description">
            If you own a helicopter, o you run a regional helicopter operator firm, a website or app that can book helicopter flights, then speak to us; we can give you access to more flights, simplify booking of helipads for landing, and manage passengers like airlines, incl. online security briefing, baggage handling software, check-in and boarding pass.
          </span>
        </div>
      </div>
      <div className="right-panel">
        <img
          alt="Logo"
          className="right-panel-logo"
          src={imgLogo}
        />
        <div className="right-panel-content">
          <span className="right-panel-title">Login</span>
          <Button onClick={() => window.location.href = "/login"} className="login-btn">
            Switch to Log in
          </Button>
        </div>
      </div>
      <a className="back-btn" href="/">
        <img
          alt="back"
          className="back-btn-icon"
          src={iconBack}
        />
      </a>
    </div>
  )
}

export default RegisterPage;