import Button from "../../components/Button";
import imgLogo from "../../assets/images/logo.png";
import iconConfirmed from "../../assets/icons/confirmed.png";
import iconBack from "../../assets/icons/back.png";

const RequestConfirmedPage = () => {
  return (
    <div className="RequestConfirmedPage">
      <div className="header-panel">
      <img
        alt="Logo"
        className="header-logo"
        src={imgLogo}
      />
      </div>
      <div className="main-panel">
        <img
          alt="Confirmed"
          className="request-confirmed-icon"
          src={iconConfirmed}
        />
        <span className="main-panel-title">
          Request to early access successful!
        </span>
        <span className="main-panel-description">
          We’ll let you know when your  access request has been approved and send you a link to be among the first users who can book flights with us.
        </span>
        <Button onClick={(e) => window.location.href = "/"} className="request-confirmed-btn">
          Done
        </Button>
      </div>
      
      <a className="back-btn" href="/register">
        <img
          alt="back"
          className="back-btn-icon"
          src={iconBack}
        />
      </a>
    </div>
  )
}

export default RequestConfirmedPage;