import imgCEO from "../../assets/images/CEO.png";
import imgCFO from "../../assets/images/CFO.png";
import imgCOO from "../../assets/images/COO.png";

function Leadership() {
  return (
    <section className="section-leadership" id="section-leadership">
      <span className="leadership-title">
        Our leadership
      </span>
      <div className="leadership-items">
        <div className="leadership-item">
          <img
            alt="ceo"
            className="leadership-item-image"
            src={imgCEO}
          />
          <span className="leadership-item-name">
            Louis
          </span>
          <span className="leadership-item-role">
            CEO
          </span>
          <span className="leadership-item-content">
            Louis Tag has more than 10 year of experience as <span className="highlight">serial-entrepreneur in travel-tech</span>. He has served in leadership as  founder of PASSNFLY, world’s first universal flight check-in software, named among Europe's <span className="highlight">Top100 startups</span> by WIRED magazine. The client list includes world leading online travel distributors. The technology connects airports and airlines with passengers and processed more than <span className="highlight">100 million flight check-ins</span>. He brings strong sales-connections in the flight distribution industry and led product strategy and development of flight related travel-tech.
          </span>
        </div>
        <div className="leadership-item">
          <img
            alt="cfo"
            className="leadership-item-image"
            src={imgCFO}
          />
          <span className="leadership-item-name">
            Nizar
          </span>
          <span className="leadership-item-role">
            CFO
          </span>
          <span className="leadership-item-content">
            Nizar is a <span className="highlight">top-tier finance executive</span>. He performed financial audits for MAZARS a leading international audit, tax and advisory firm, present in 90+ countries and 20,000+ employees. Oversaw M&amp;A deals for LAZARD, a global financial advisory and asset management firm with 4,000+ employees. He served as financial executive for PwC (world's top-tier accounting and audit firm) in the area of <span className="highlight">business valuation &amp; financial modelling</span> over the past 3 years.
          </span>
        </div>
        <div className="leadership-item">
          <img
            alt="coo"
            className="leadership-item-image"
            src={imgCOO}
          />
          <span className="leadership-item-name">
            Richard
          </span>
          <span className="leadership-item-role">
            Helicopter Operations
          </span>
          <span className="leadership-item-content">
            Richard managed the last <span className="highlight">15 years helicopter flight and fleet operations</span> in the United Arab Emirates for the largest and most prestigious helicopter operator firm, including a wide range of areas such as flight operations, operational cost optimization, VIP passenger flights, maintenance of fleet, selection of aircrafts, security on airfield, clearance of flights for departure, and <span className="highlight">air traffic control</span> management.

          </span>
        </div>
      </div>
    </section>
  );
}

export default Leadership;
