import About from "../about-section/about";
import Feature from "../feature-section/feature";
import Tours from "../tours-section/tours";
import Leadership from "../leadership-section/leadership";
import Contact from "../contact-section/contact";
import Stories from "../stories-section/stories";

function Main() {
  return (
    <main>
      <About />
      <Feature />
      <Tours />
      <Leadership />
      <Stories />
      <Contact />
    </main>
  );
}

export default Main;
