const setEmail = ({ email }) => {
  return {
    type: "SET_EMAIL",
    email,
  };
};

const setPhoneNumber = ({ phone_code, phone_number }) => {
  return {
    type: "SET_PHONE_NUMBER",
    phone_code,
    phone_number,
  };
};

export const verificationActions = {
  setEmail,
  setPhoneNumber,
};
