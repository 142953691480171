const TextInput = ({type = "text", ...props}) => {
  return (
    <input
      {...props}
      type={type}
      className={`text-input ${props.className || ""}`}
    />
  );
}

export default TextInput;
