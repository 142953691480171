import React, { useState } from 'react';
import iconDown from "../../assets/icons/down.svg";

function FaqItem({title, description}) {
  const [bOpen, setOpen] = useState(false);

  return (
    <div
      className="stories-faq-item"
      onClick={() => setOpen(!bOpen)}
    >
      <div className="faq-item-header">
        <span className="faq-item-title">
          {title}
        </span>
        <img
          alt="phone"
          className={`faq-item-icon ${bOpen ? "open" : "close"}`}
          src={iconDown}
        />
      </div>
      {bOpen ? (
        <span className="faq-item-text">
          {description}
        </span>
      ) : ""}
    </div>
  );
}

export default FaqItem;
