const Button = ({...props}) => {
  function onClick(e) {
    if (!props.disabled && props.onClick) {
      props.onClick(e);
    }
  }

  return (
    <button
      {...props}
      className={`btn ${props.className || ""}`}
      onClick={(e) => onClick(e)}
    >
      {props.children}
    </button>
  );
}

export default Button;
