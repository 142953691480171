import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import TextInput from "../../components/TextInput";
import imgLogo from "../../assets/images/logo.png";
import iconBack from "../../assets/icons/back.png";

const VerificationPage = () => {
  const verificationSelector = useSelector(state => state.verification);
  const { verify_method } = useParams();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (!verificationSelector) return;
    if (verificationSelector.email) {
      let _email = verificationSelector.email;
      const emailService = _email.substring(_email.indexOf("@"));
      const newEmail = _email.slice(0, 2) + "****" + emailService;
      setEmail(newEmail);
    }

    if (verificationSelector.phone_number) {
      const _phoneCode = verificationSelector.phone_code;
      let _phoneNumber = verificationSelector.phone_number;
      const newPhoneNumber = _phoneCode + _phoneNumber.slice(0, 2) + "****" + _phoneNumber.slice(_phoneNumber.length - 2);
      setPhoneNumber(newPhoneNumber);
    }
  }, [verificationSelector]);
  
  if (verify_method !== "sms" && verify_method !== "email") {
    window.location.href = "/";
    return null;
  }

  return (
    <div className="VerificationPage">
      <div className="header-panel">
        <img
          alt="Logo"
          className="header-logo"
          src={imgLogo}
        />
      </div>
      <div className="main-panel">
        <span className="main-panel-title">Enter your {verify_method === "sms" ? "SMS" : "Email"} passcode</span>
        <span className="main-panel-description">
          This is the 6-digit security passcode {verify_method === "sms" ? `you received via SMS to ${phoneNumber}` : `we sent to ${email}`}
        </span>
        <div className="input-layout">
          <div className="input-fields">
            {[...Array(6).keys()].map(index => (
              <TextInput
                key={index}
                className="passcode-input"
                maxlength="1"
              />
            ))}
          </div>
          {verify_method === "email" ? (
            <div className="resend-layout">
              <span>Send <a href="/login">SMS</a> instead</span>
            </div>
          ) : (
            <div className="resend-layout">
              <span>Resend code in 51s...</span>
              <span>Send <a href="/login">email</a> instead</span>
            </div>
          )}
          <div className="support-layout">
            <b className="highlight">No code received?</b> If you don’t receive a code then contact our support <a href="#">here</a>.
          </div>
        </div>
      </div>
      <a className="back-btn" href="/login">
        <img
          alt="back"
          className="back-btn-icon"
          src={iconBack}
        />
      </a>
    </div>
  )
}

export default VerificationPage;