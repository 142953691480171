import parse from 'html-react-parser';
import Header from "../../commons/header/header.jsx";
import Footer from "../../commons/footer-section/footer";
import { PRIVACY_POLICY_ITEMS } from "../../constants";

const PrivacyPolicyPage = () => {
  return (
    <div className="PrivacyPolicyPage">
      <Header />
      <div className="privacy-policy-content">
        <span className="privacy-policy-title">Privacy policy</span>
        <span className="privacy-policy-address">
          Private Policy Service Fast Track<br/>
          V0.1 - 27th of November 2019<br/>
          Advanced Travel Technologies LTD<br/>
          11 Staple Inn, London, United Kingdom, WC1V 7QH<br/>
          <a href='mailto:privacy@fasttrack.flights'>privacy@fasttrack.flights</a>
        </span>
        <span className="privacy-policy-description">
          The following information is provided in compliance with art. 13 of Regulation 2016/679 (GDPR) to users who access the application and relate to all personal data processed in the manner indicated below.<br /><br />
          Please read our Privacy Policy carefully as it explains the following:<br /><br />
          <ol>
            <li>What personal information is collected from you and for which purpose (Section 2 and 3)</li>
            <li>The measures to safeguard the access and the security of your Personal Data (Section 4)</li>
            <li>Your rights to access, rectify, erase your Personal Data, and object to the processing of your Personal Data (Section 9).</li>
          </ol>
          <br />
          <span>To use the Service, you must first acknowledge and agree to our Privacy Policy. As a result, you cannot use the Service without first accepting our Privacy Policy.</span>
        </span>
        <div className="privacy-policy-items">
          {PRIVACY_POLICY_ITEMS.map((privacyItem, index) => (
            <div className="privacy-policy-item" key={index}>
              <b className="privacy-policy-item-title">{index + 1}. {privacyItem.title}</b>
              <span>{parse(privacyItem.content)}</span>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PrivacyPolicyPage;